import React, { useState } from 'react'
import classnames from 'classnames'
// import {
// 	FaSass,
// 	FaHtml5,
// 	FaReact,
// 	FaMobileAlt,
// 	FaRocket,
// 	FaOsi
// } from 'react-icons/fa';

import { Link } from 'gatsby'

import '../styles/styles.scss'
// import Emoji from './emoji';
import mowLogo from '../images/mow-for-community-wellness.svg'
import mowTextLeftLogo from '../images/the-mask-text-left.svg'
import iconSocial from '../images/icon-social.svg'
import iconPhysical from '../images/icon-physical.svg'
import iconEconomical from '../images/icon-economical.svg'
import getStartedSVG from '../images/get-started.svg'

import featOne from '../images/biz-30hop.jpg'
import featTwo from '../images/biz-two.jpg'
import featThree from '../images/biz-three.jpg'
import featFour from '../images/biz-four.jpg'

import heroIconMasks from '../images/icon-hero-masks.svg'
import heroIconHygiene from '../images/icon-hero-hygiene.svg'
import heroIconHealth from '../images/icon-hero-health.svg'

import chartSideBySide from '../images/chart-sideby-covid.jpg'

const Midsection = () => {
  const [ isModalOpen, setIsModalOpen ] = useState(false);
  const [ modalCopy, setModalCopy ] = useState('');
  const triggerModal = (event) => {
    const copy = event.currentTarget.dataset.modalMessage;
    setModalCopy(copy);
    setIsModalOpen(true);
  }
  const modalClass = classnames('modal', { 'is-active': isModalOpen });
  return (
  <React.Fragment>
    <div className={modalClass}>
      <div className="modal-background" onClick={() => {setIsModalOpen(false)}}/>
      <div className="modal-content">
        {/* place any content you want here */}
        <div className="container">
          <article className="message">
            <div className="message-body" dangerouslySetInnerHTML={{ __html: modalCopy }} />
          </article>
        </div>
      </div>
      <button
        className="modal-close is-large"
        aria-label="close"
        onClick={() => {setIsModalOpen(false)}} />
    </div>
    <section
      className="section mow-border-top home-about-section"
      key="mow-banner-section"
    >
      <div className="container has-text-centered">
        <div className="columns">
          <div className="column is-one-third">
            <img src={mowTextLeftLogo} className="home-about-img" />
          </div>
          <div className="column is-6">
            <p className="is-spaced has-text-left home-about">
              is a <strong>Positive Empowering Approach</strong> that promotes
              responsible behavior that is necessary to keep society open during
              this pandemic while benefiting the following areas of wellness
            </p>
            <div className="level">
              <div className="level-item home-about-icon">
                <img src={iconSocial} alt="Social" width="50" height="50" />
                <span className="is-inline-block is-uppercase">Social</span>
              </div>
              <div className="level-item home-about-icon">
                <img src={iconPhysical} alt="Physical" width="50" height="50" />
                <span className="is-inline-block is-uppercase">Physical</span>
              </div>
              <div className="level-item home-about-icon">
                <img
                  src={iconEconomical}
                  alt="Economical"
                  width="50"
                  height="50"
                />
                <span className="is-inline-block is-uppercase">Economical</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


    <section>
      <div className="divider-title">
        <h3>Here's What We're Doing</h3>
      </div>
      <div className="columns is-marginless v-align-center">
        <div className="column home-bg-biz-grocery is-hidden-tablet">

        </div>

        <div className="column home-half-text-left">

            <h2>For Businesses</h2>
            <h5>Give Your Customers & Employees Confidence</h5>
            <p>‘Mask of Wellness’ is a movement to promote ‘Covid-Conscious’ Businesses for free with a positive empowered method to improve the health and wealth of communities through three basic impactful measures:
            </p>
            <div className="columns">

              <div className="column home-biz-icon"
                data-modal-message="
                <div class=&quot;divider-title&quot;>
                  <h3>Masks</h3>
                </div>
                <div class=&quot;modal-main-content&quot;>
                <p>COVID-19 is often spread by infected people directly to others (within 6 feet) or indirectly contaminating surfaces. You can get the virus by coming into contact with an infected person's respiratory droplets when they cough, sneeze, or talk. Asymptomatic or pre-symptomatic people do not feel or show symptoms while infected and spread the virus.</p>
                <p>'Mask of Wellness’ requirements align with CDC recommendations and we strongly recommend you follow the national and local safety standards relevant to your business.
                  <ul>
                    <li>Wear masks in all public and shared workspaces at all times except while eating or drinking in designated areas.</li>
                    <li>With attention to proper personal/surface/distancing hygiene, businesses may allow individuals to remove masks when alone in private work areas such as personal offices,  cubicles, and desks.</li>
                    <li>With proper physical distancing, businesses may allow meetings without masks in a private office. Be aware that droplets emitted by breathing and talking may fall on surfaces around an individual and potentially contaminate surfaces, food, and objects.</li>
                    <li>Wear masks while working in customers’ homes and businesses.</li>
                    <li>Masks or cloth face coverings should cover the nose and mouth and fit comfortably to reduce the need for adjustment.</li>
                    <li>Provide a means to sanitize hands if the mask or face is touched.</li>
                    <li>Delivery drivers should wear a mask whenever packages or food containers could be contaminated (i.e. points of pickup and delivery as well as potentially while driving).</li>
                    <li>It is up to a business if the patrons are required to wear a mask.</li>
                  </ul>
                </p>
                </div>
                "
                onClick={triggerModal}>
                <img src={heroIconMasks} alt="icon" className="hero-icon-1" />
                <p className="has-text-centered">Masks worn by all employees</p>
                <p className="has-text-centered icon-learn">Learn more</p>
            </div>

            <div className="column home-biz-icon"
                data-modal-message="
                <div class=&quot;divider-title&quot;>
                  <h3>Hygiene</h3>
                </div>
                <div class=&quot;modal-main-content&quot;>
                <p>
                  <ul>
                    <li><strong>Personal Hygiene -</strong> The best way to protect yourself and others from germs is to regularly wash your hands with soap and water for 20 seconds or use hand sanitizer with at least 60% alcohol.  In addition, the hands should be sanitized promptly if one touches a potentially contaminated surface or your face.</li>
                    <li><strong>Surface Hygiene -</strong> Practice routine cleaning of frequently-touched surfaces. The ‘Businesses’ section provides excellent resources pertaining to safe and effective methods of cleaning and disinfecting. If working in another’s home or business, methods should be established to sanitize potentially contaminated areas prior to leaving.</li>
                    <li><strong>Distancing Hygiene -</strong> When possible, It’s important to physically distance yourself from employees and patrons. Maintain at least 6 feet of distance (about two arms’ length) from others if possible to decrease the chance of spread. Consider strategies in your work environment to help with this endeavor, i.e. stagger work shifts & breaks, avoid in-person meetings and large groups, utilize physical barriers if necessary, and avoid non-essential travel.</li>
                  </ul>
                </p>
                <p>Ultimately, we formally recommend you follow national and local safety standards relevant to your business.</p>
                </div>
                "
                onClick={triggerModal}>
                <img src={heroIconHygiene} alt="icon" className="hero-icon-2" />
                <p className="has-text-centered">Personal, surface, and distance hygiene</p>
                <p className="has-text-centered icon-learn">Learn more</p>
            </div>
            <div className="column home-biz-icon"
                data-modal-message="
                <div class=&quot;divider-title&quot;>
                  <h3>Health</h3>
                </div>
                <div class=&quot;modal-main-content&quot;>
                <p>
                  <strong>Daily health self-certification -</strong> This step is intended to decrease the likelihood of spreading the virus in your work environment by keeping sick individuals out of the workplace. The list of possible COVID-19 symptoms is extensive and will need to be reassessed periodically. The ‘Mask of Wellness’ downloadable kit contains an example of a  COVID-19 self-screening form to help. This daily health self-certification has a list of primary symptoms associated with COVID-19. If an employee  presents to work with related symptom(s) or develops symptom(s) while at work they should leave the workplace immediately and notify a manager. Ideally, the list of symptoms should be known by all employees and not present to work if symptoms are present and notify a manager.  Leave policies should be flexible and staying home while exhibiting symptoms should come without punishment. Ideally, temperature should be taken before or at the beginning of the work day.  If clinical concern is raised by a healthcare professional of COVID-19 then a manager should be notified with recommendation of notifying potential contacts. Ultimately, we formally recommend you follow national and local safety standards relevant to your business.
                </p>
                <p>Ultimately, we formally recommend you follow national and local safety standards relevant to your business.</p>
                <p>Please refer to the CDC and OSHA regarding proper hygiene and airflow issues.</p>
                </div>
                "
                onClick={triggerModal}>
                <img src={heroIconHealth} alt="icon" className="hero-icon-3" />
                <p className="has-text-centered">Daily health self-certification</p>
                <p className="has-text-centered icon-learn">Learn more</p>
              </div>
            </div>

      </div>

      <div className="column home-bg-biz-grocery is-hidden-mobile">

      </div>
    </div>

    </section>


    <section>
      <div className="columns is-marginless v-align-center">
        <div className="column square-bg-biz-toolkit">
        </div>
        <div className="column home-half-text-left">
            <h2>Free Toolkit</h2>
            <h5>For 'Covid-Conscious' Businesses</h5>
            <p>What’s in it for you? Joining our movement gives your customers confidence to patronize your business; knowing it’s following our three simple ‘Covid-Conscious’ practices of masks, hygiene, and health.</p>
            <p>We’re providing a free, downloadable ‘Mask of Wellness’ kit to promote your ‘Covid-Conscious’ business today!</p>
            <Link to="/get-started/">
              <button className="button">SIGN UP</button>
            </Link>
        </div>
    </div>
    </section>

{/*
    <section className="section feat-biz" key="mow-featured-biz-tiles">
      <div className="container-fluid has-text-centered">
        <h3 className="title is-3">FEATURED BUSINESSES</h3>


        <div className="columns">
          <div className="column">
            <Link to="/for-businesses/">
              <div className="card">
                <div className="card-content is-paddingless">
                  <figure className="image is-4by3">
                    <img src={featOne} />
                  </figure>
                </div>
                <footer>
                  <div className="card-footer-item feat-biz-overlay">
                    <h6 className="subtitle is-size-7 is-uppercase">
                      Restaurant
                    </h6>
                    <h4 className="title is-size-4 is-uppercase">30HOP</h4>
                  </div>
                </footer>
              </div>
            </Link>
          </div>

          <div className="column">
            <Link to="/for-businesses/">
              <div className="card">
                <div className="card-content is-paddingless">
                  <figure className="image is-4by3">
                    <img src={featTwo} />
                  </figure>
                </div>
                <footer>
                  <div className="card-footer-item feat-biz-overlay">
                    <h6 className="subtitle is-size-7 is-uppercase">Salon</h6>
                    <h4 className="title is-size-4 is-uppercase">
                      Thompson & Co. Salon
                    </h4>
                  </div>
                </footer>
              </div>
            </Link>
          </div>

          <div className="column">
            <Link to="/for-businesses/">
              <div className="card">
                <div className="card-content is-paddingless">
                  <figure className="image is-4by3">
                    <img src={featThree} />
                  </figure>
                </div>
                <footer>
                  <div className="card-footer-item feat-biz-overlay">
                    <h6 className="subtitle is-size-7 is-uppercase">
                      Restaurant
                    </h6>
                    <h4 className="title is-size-4 is-uppercase">Pagliai's</h4>
                  </div>
                </footer>
              </div>
            </Link>
          </div>

          <div className="column">
            <Link to="/for-businesses/">
              <div className="card">
                <div className="card-content is-paddingless">
                  <figure className="image is-4by3">
                    <img src={featFour} />
                  </figure>
                </div>
                <footer>
                  <div className="card-footer-item feat-biz-overlay">
                    <h6 className="subtitle is-size-7 is-uppercase">
                      Construction
                    </h6>
                    <h4 className="title is-size-4 is-uppercase">
                      Boyd Crosby Construction
                    </h4>
                  </div>
                </footer>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </section>
    <section className="section" key="mow-banner-section-two">
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1 has-text-centered">
            <p className="is-marginless">
              Thanks to these businesses for helping jumpstart the cause and for enhancing the safety of employees and patrons in our communities while benefiting the physical, social, and economic well-being with a positive self-empowered approach joining our community.  ‘Mask of Wellness’ certification is provided to Covid-Conscious businesses that supports three simple measures:
            </p>
          </div>
        </div>
        <div className="columns">
          <div className="column is-narrow center-block is-paddingless">
            <p>
              <ul>
                <li><span className="accent">1</span> Mask worn by all employees </li>
                <li><span className="accent">2</span> Personal, surface, and distance hygiene</li>
                <li><span className="accent">3</span> Daily health self-certification</li>
              </ul>
            </p>
          </div>
        </div>
      </div>
    </section>
    */}

    <section
      className="section is-paddingless mow-border-top"
      key="mow-forbiz-getstarted"
    >
      <div className="container-fluid">
        <div className="columns is-marginless v-align-center footer-cta-section">
          <div className="column is-3 has-background-primary footer-block">
            <img src={getStartedSVG} className="footer-get-started" />
          </div>
          <div className="column is-8 sign-up-col">
            <h5 className="title is-4">SIGN UP YOUR BUSINESS</h5>
            <p>
              Our collaborative efforts WILL play a role in preventing COVID-19 reoccurrences and spikes. Consumers will seek out ‘Covid-Conscious’ Businesses thus benefiting the Health, Wealth, and Wellness of Individuals and Businesses in all of OUR communities.
            </p>
            <Link to="/get-started/">
              <button className="button">SIGN UP</button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  </React.Fragment>
  );
}

export default Midsection
